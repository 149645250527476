<template>
  <div class="common-layout">
    <aside class="common-layout__aside">
      <side-bar />
    </aside>
    <div class="common-layout__container">
      <header class="common-layout__container-header">
        <base-header />
      </header>
      <main class="common-layout__main">
        <slot />
        <!-- <pop-up/> -->
      </main>

      <footer class="common-layout__footer">
        <div class="footer-container">
          <nuxt-link class="menu__link" to="/">
            <nuxt-icon class="menu__icon" name="main"> </nuxt-icon>
            Главная
          </nuxt-link>
          <nuxt-link class="menu__link" to="/deals">
            <nuxt-icon class="menu__icon" name="briefcase"> </nuxt-icon>
            Сделки
          </nuxt-link>
          <span @click="PopUpControl.callPopUp('CreateDeal')" class="menu__link" to="/">
            <nuxt-icon class="menu__icon" name="plus-circle"> </nuxt-icon>
            Создать
          </span>
          <nuxt-link class="menu__link" to="/">
            <nuxt-icon class="menu__icon" name="chat"> </nuxt-icon>
            Чат
          </nuxt-link>
          <nuxt-link class="menu__link" to="/">
            <nuxt-icon class="menu__icon" name="Frame"> </nuxt-icon>
            Ещё
          </nuxt-link>
        </div>
      </footer>
    </div>
  </div>
  <cookie-notification />
</template>

<script lang="ts" setup>
import { useFormStore } from '~/store/user'
import { YmapStore } from '~/store/Ymap'
import { writePersistentLocalStorage } from '~/services/LocalStorage/localStorage'
import { useQuasar } from 'quasar'
import 'animate.css'
import { PopUpStore } from '~/store/popUp'
import { setCookieExpires } from '~/helpers/Cookie/setCookie'

const PopUpControl = PopUpStore()
const userStore = useFormStore()
const storeYmap = YmapStore()

const loadYmap = () => {
  storeYmap.loadYmap()
  storeYmap.$state.scriptYmap.onload = () => {
    if ((window as any).ymaps) {
      ;(window as any).ymaps.ready().then(() => {
        ;(window as any).ymaps.geolocation
          .get({
            autoReverseGeocode: true,
          })
          .then(function (result: any) {
            const rawAddress = result.geoObjects.get(0).properties.get('metaDataProperty')
            const adComponents = rawAddress.GeocoderMetaData.Address.Components
            if (adComponents && Array.isArray(adComponents) && adComponents.length > 0) {
              adComponents.forEach((item) => {
                storeYmap.$state.geoApiCity.GeocoderMetaData.AddressDetails.Country.AdministrativeArea.Locality.LocalityName =
                  item.kind == 'locality' ? item.name : item.kind == 'province' ? item.name : ''
              })
            }
            if (storeYmap.$state.geoApiCity) {
              writePersistentLocalStorage('geo_api_city', storeYmap.$state.geoApiCity)
            }
          })
      })
    }
  }
  document.head.appendChild(storeYmap.$state.scriptYmap)
}

const q = useQuasar()

onBeforeMount(() => {
  loadYmap()
  userStore.fetchSurvey()
  userStore.setScreenSizeInCSS()
  setCookieExpires()
})

watch(
  () => q.appVisible,
  (val) => {
    // тут будет логика обработки переключения вкладок браузера
  },
)
</script>

<style lang="scss">
@import '../assets/styles/_global';
</style>
